import React from 'react'
import ReactDOM from 'react-dom'
import 'shared-ui/assets/styles/global.css'
import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

const errorsToWarn = ['Warning:']
const oldConsError = console.error

console.error = function (...args) {
  let toWarn = typeof args[0] === 'string' && errorsToWarn.some(s => args[0].startsWith(s))
  toWarn ? console.warn(...args) : oldConsError(...args)
}
