import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'

function getProductPU({ pu, crmProduct: { tobaccoCategory } = {} } = {}) {
  if (pu > 0) return pu
  return tobaccoCategory === 'RMC' ? 10 : 1
}

function getUnitConversion({
  crmProduct: { conversionByUnit = {}, defaultUnit } = {},
  selectedUnit,
}) {
  return conversionByUnit[selectedUnit] || conversionByUnit[defaultUnit]
}

async function remotelyUpdateCartProduct({
  api,
  pid,
  crmProduct,
  crmProduct: { tobaccoProductCode: code },
  price,
  amount = 0,
  originalAmount = 0,
  selectedUnit,
  originalUnit,
  onSuccess,
  onError,
  distributorCode,
  isSpecialOffer,
}) {
  try {
    const { request } = api.updateCartProduct({
      pid,
      code,
      amount,
      selectedUnit,
      distributorCode,
      isSpecialOffer,
    })
    await request

    let delta

    if (isSpecialOffer) delta = amount - originalAmount
    else {
      const { conversionByUnit } = crmProduct
      delta =
        amount * conversionByUnit[selectedUnit] - originalAmount * conversionByUnit[originalUnit]
    }

    if (delta > 0) AnalyticsEvents.pushAddToCart(crmProduct, delta, price)
    else if (delta < 0) AnalyticsEvents.pushRemoveFromCart(crmProduct, -delta, price)
    else if (selectedUnit) {
      //TODO how should the unit change be tracked?
    }

    if (onSuccess) onSuccess()
  } catch (err) {
    console.error('Updating product amount failed:', err)
    if (onError) onError(err)
  }
}

async function remotelyUpdateCart({
  api,
  distributorCode,
  products,
  originalProducts,
  onSuccess,
  onError,
}) {
  try {
    const cleanProducts = products.map(p => {
      const {
        _id,
        crmProduct: { tobaccoProductCode: code },
        amount,
        selectedUnit,
      } = p
      return {
        _id,
        code,
        amount,
        selectedUnit,
      }
    })

    const { request } = api.updateCart(distributorCode, cleanProducts)
    await request

    trackCartUpdate({ products, originalProducts })

    if (onSuccess) onSuccess()
  } catch (err) {
    console.error('Update of product list failed:', err)
    if (onError) onError(err)
  }
}

function trackCartUpdate({ products, originalProducts }) {
  const originalProductByOid = originalProducts.reduce((acc, product) => {
    acc[product._id] = product
    return acc
  }, {})

  for (let product of products) {
    const originalProduct = originalProductByOid[product._id]

    const {
      amount = 0,
      selectedUnit,
      crmProduct,
      crmProduct: { defaultUnit, conversionByUnit },
    } = product
    const { amount: originalAmount = 0, selectedUnit: originalUnit = defaultUnit } =
      originalProduct || {}

    let delta = 0
    if (defaultUnit) {
      delta =
        amount * conversionByUnit[selectedUnit || defaultUnit] -
        originalAmount * conversionByUnit[originalUnit]
    } else {
      delta = amount - originalAmount
    }

    if (delta === 0) continue

    const price = product.priceListPrice
    if (delta > 0) AnalyticsEvents.pushAddToCart(crmProduct, delta, price)
    else AnalyticsEvents.pushRemoveFromCart(crmProduct, -delta, price)
  }
}

async function reorder({ api, orderId }) {
  const { request } = api.repeatOrder(orderId)
  await request
  const { newCart, oldCart } = await request
  AnalyticsEvents.pushEvent('reOrder')

  const { products } = newCart
  const { products: originalProducts } = oldCart
  const productsByOid = products.reduce((acc, product) => {
    acc[product._id] = product
    return acc
  }, {})

  originalProducts.forEach(product => {
    if (!productsByOid[product._id]) products.push({ ...product, amount: 0 })
  })

  trackCartUpdate({ products, originalProducts })
}

export {
  getProductPU,
  getUnitConversion,
  remotelyUpdateCartProduct,
  remotelyUpdateCart,
  trackCartUpdate,
  reorder,
}
